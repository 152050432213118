import style from "./Home.module.css";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { ShepherdTourContext } from "react-shepherd"; // étapes pour l'onboarding tour "création d'une catégorie"
import { useNavigate, useLocation } from "react-router-dom";

import qs from "qs";
import { createProductSteps } from "../../onboardingtours/create-product.steps";
import { createCategorySteps } from "../../onboardingtours/create-category.steps";
import { detailledCatalogSteps } from "../../onboardingtours/detailled-catalog.steps";
import Winylo from "../../winylo";
import { dateToString, isAdmin, MY_DOMAIN, startOnBoardingTour, useQueryParams } from "../../utils/utils";
import { detailledProductSteps } from "../../onboardingtours/detailled-product.steps";
import { createCatalogSteps } from "../../onboardingtours/create-catalog.steps";
import { detailledCategorySteps } from "../../onboardingtours/detailled-category.steps";
import { shareCatalogSteps } from "../../onboardingtours/share-catalog.steps";
import classNames from "classnames";
import { AwesomeQRCode } from "@awesomeqr/react";

import mediaLogo from "./img/media-icon.svg";
import catalogueLogo from "./img/catalogue-icon.svg";
import icons from "../../utils/icons";
import { UserContextType, useUser } from "../../context/UserContext";
import { useInfiniteQuery } from "react-query";
import api from "../../api/api";
import { Patchnote } from "../../api/_type";
import PatchnoteItem from "./PatchnoteItem";

interface Props {
  title: string;
  children?: ReactElement;
  handleClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

function HomeContainer(props: Props): ReactElement {
  return (
    <>
      <div className={style.homeContainer} onClick={(e) => props.handleClick && props.handleClick(e)}>
        <div className={style.homeContainerTitle}>{props.title}</div>
        <div className={style.homeContainerChildren}>{props.children}</div>
      </div>
    </>
  );
}

export default function Home(): ReactElement {
  const [patchNotes, setPatchNotes] = useState<Patchnote[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isModalUpdateOpen, setIsModalUpdateOpen] = useState<boolean>(false);
  const [isModalNeedToCreateCategoryOpen, setIsModalNeedToCreateCategoryOpen] = useState<boolean>(false);
  const [isModalMobileAppOpen, setIsModalMobileAppOpen] = useState<boolean>(false);

  const tour = useContext(ShepherdTourContext);
  const history = useNavigate();
  const [user] = useUser() as UserContextType;

  const [onBoardingStart, setOnBoardingStart] = useState<number>(-1);

  let queryParams = useQueryParams();

  const { isLoading, hasNextPage, fetchNextPage, isFetching } = useInfiniteQuery(
    ["patchNotes"],
    ({ pageParam = 1 }) => api.patchnotes.getPatchnotes({ page: pageParam }),
    {
      onSuccess: (data) => {
        setPatchNotes(data.pages.map((p) => p.items).flat());
        setTotalCount(data.pages[0].pagination.totalCount);
      },
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.pagination.current < lastPage.pagination.endPage) {
          return lastPage.pagination.current + 1;
        } else {
          return undefined;
        }
      },
      keepPreviousData: true,
    }
  );

  const ContainerPatchNotes = () => {
    let nbPreviews = 0;
    const maxPreviews = 6;

    function getPreviews() {
      return patchNotes.slice(0, 6).map((pn) => <PatchnoteItem patchnote={pn} short />);
    }

    return (
      <>
        <span className={style.textContent}>Découvrez les dernières nouveautés</span>
        <div className={style.itemList}>
          {patchNotes ? <>{getPreviews()}</> : <div>‎ </div>}
          <span className={style.clickableItem} style={{ display: "flex", justifyContent: "center" }}>
            Voir plus
          </span>
        </div>
      </>
    );
  };

  function ContainerMobileApps() {
    return (
      <>
        <span className={style.textContent}>Téléchargez l'application mobile</span>

        <span className={style.textContent}></span>
        <div className={style.itemList}>
          <div className={style.listItem} style={{ fontWeight: 600 }}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Application mobile gratuite
          </div>
          <div className={style.listItem}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Accédez à vos catalogues à distance
          </div>
          <div className={style.listItem}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Partagez vos catalogues depuis votre mobile
          </div>
          <div className={style.listItem}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Facile d'accès pour vos commerciaux
          </div>
        </div>
      </>
    );
  }

  function ContainerWhyWinylo() {
    return (
      <>
        <span className={style.textContent}>
          CKTools est une bibliothèque d'application destinée à toutes les entreprises qui souhaitent se digitaliser en toute simplicité.
        </span>
        <div className={style.itemList}>
          <div className={style.listItem}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Clé en main
          </div>
          <div className={style.listItem}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Simple d'utilisation
          </div>
          <div className={style.listItem}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Accessible
          </div>
          <div className={style.listItem}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Sans engagement
          </div>
          <div className={style.listItem}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Testable gratuitement
          </div>
        </div>
      </>
    );
  }

  function ContainerTutorial() {
    return (
      <>
        <span className={style.textContent}>Découvrez les tutoriels participatifs</span>
        <div className={style.itemList}>
          <div className={classNames(style.listItem, style.clickable)} onClick={() => setOnBoardingStart(0)}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Créer un produit
          </div>
          <div className={classNames(style.listItem, style.clickable)} onClick={() => setOnBoardingStart(1)}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Options d'un produit
          </div>
          <div className={classNames(style.listItem, style.clickable)} onClick={() => setOnBoardingStart(2)}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Créer une catégorie
          </div>
          <div className={classNames(style.listItem, style.clickable)} onClick={() => setOnBoardingStart(3)}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Options d'une catégorie
          </div>
          <div className={classNames(style.listItem, style.clickable)} onClick={() => setOnBoardingStart(4)}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Créer un catalogue
          </div>
          <div className={classNames(style.listItem, style.clickable)} onClick={() => setOnBoardingStart(5)}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Options d'un catalogue
          </div>
          <div className={classNames(style.listItem, style.clickable)} onClick={() => setOnBoardingStart(6)}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Partager un catalogue
          </div>
        </div>
      </>
    );
  }

  function ContainerLinkableApps() {
    return (
      <>
        <span className={style.textContent}>Nos applications sont connectables entre elles</span>
        <div className={style.itemContainer}>
          <div className={style.linkableContainer}>
            <img src={catalogueLogo} className={classNames(style.linkableLogo, style.catalogueLogo)} />
            <FontAwesomeIcon icon={icons.v6_faArrowRightArrowLeft} className={style.linkableIcon} />
            <img src={mediaLogo} className={classNames(style.linkableLogo, style.mediaLogo)} />
          </div>
        </div>
      </>
    );
  }

  function toggleDownloadAppModal() {
    setIsModalMobileAppOpen((o) => !o);
  }

  function checkDate(index: number, array: Patchnote[], patchnote: Patchnote) {
    return index === 0 || dateToString(patchnote.scheduledAt) !== dateToString(array[index - 1]?.scheduledAt);
  }

  useEffect(() => {
    if (onBoardingStart !== -1) {
      switch (onBoardingStart) {
        case 0:
          startOnBoardingTour(tour, createProductSteps(history));
          break;
        case 1:
          startOnBoardingTour(tour, detailledProductSteps(history));
          break;
        case 2:
          startOnBoardingTour(tour, createCategorySteps(history));
          break;
        case 3:
          startOnBoardingTour(tour, detailledCategorySteps(history));
          break;
        case 4:
          startOnBoardingTour(tour, createCatalogSteps(history));
          break;
        case 5:
          startOnBoardingTour(tour, detailledCatalogSteps(history));
          break;
        case 6:
          startOnBoardingTour(tour, shareCatalogSteps(history));
          break;
        default:
          console.log(`%conBoardinStart : bad index (${onBoardingStart})`, "color: red");
          break;
      }
      setOnBoardingStart(-1);
    }
  }, [onBoardingStart]);

  useEffect(() => {
    let startIndex = qs.parse(window.location.search, { ignoreQueryPrefix: true }).onBoardingStart;
    if (startIndex && !isNaN(startIndex as any)) {
      setOnBoardingStart(parseInt(startIndex as string));
    }
  }, []);

  useEffect(() => {
    let startIndex = queryParams.get("onBoardingStart");
    if (startIndex && !isNaN(startIndex as any)) {
      setOnBoardingStart(parseInt(startIndex as string));
    }
  }, [queryParams]);

  return (
    <div className={style.bigContainer}>
      <Winylo.Card title="Accueil" className={style.card}>
        <div className={style.container}>
          {isAdmin(user) && <HomeContainer title="Premiers pas">{ContainerTutorial()}</HomeContainer>}
          {/* <HomeContainer title="Parrainage">{ContainerSponsorship()}</HomeContainer> */}
          <HomeContainer title="Mise à jour" handleClick={() => setIsModalUpdateOpen(true)}>
            {ContainerPatchNotes()}
          </HomeContainer>
          <HomeContainer title="Applications mobile" handleClick={() => toggleDownloadAppModal()}>
            {ContainerMobileApps()}
          </HomeContainer>
          <HomeContainer title="Applications connectables">{ContainerLinkableApps()}</HomeContainer>
          {/* <HomeContainer title="Vidéo explicative">{ContainerVideo()}</HomeContainer> */}
          <HomeContainer title="Pourquoi CKTools ?">{ContainerWhyWinylo()}</HomeContainer>
          {/* <div style={{ flexBasis: "32%" }}></div> */}
        </div>
      </Winylo.Card>

      <Winylo.Modal
        isOpen={isModalUpdateOpen}
        onClose={() => setIsModalUpdateOpen(false)}
        closable
        title={"Mises à jour"}
        modalStyle={{ content: { width: "50rem" } }}
      >
        <div style={{ maxHeight: "50vh", overflowY: "scroll" }}>
          {patchNotes.map((patchNote, key, array) => (
            <React.Fragment key={key}>
              <div key={key} style={{ display: "flex", flexDirection: "column" }}>
                {checkDate(key, array, patchNote) && (
                  <span style={{ fontWeight: 800, marginBottom: "0.5rem" }}>Mise à jour du {dateToString(patchNote.scheduledAt)}</span>
                )}
                <PatchnoteItem patchnote={patchNote} />
                <Winylo.Separator style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }} color="gray" />
              </div>
            </React.Fragment>
          ))}
        </div>
      </Winylo.Modal>

      <Winylo.Modal isOpen={isModalMobileAppOpen} onClose={() => toggleDownloadAppModal()} closable title={"Télécharger l'application"}>
        <div style={{ textAlign: "center" }}>
          <img
            src={`${process.env.PUBLIC_URL}/mobile/google-play-badge.png`}
            style={{ width: "66%", cursor: "pointer" }}
            onClick={() => window.open("https://play.google.com/store/apps/details?id=com.winylo.catalogue", "_blank")}
          />
          <img
            src={`${process.env.PUBLIC_URL}/mobile/apple-store-badge.svg`}
            style={{ width: "66%", cursor: "pointer", marginTop: "0.25rem" }}
            onClick={() => window.open("https://apps.apple.com/fr/app/catalogue-digital-winylo/id1589031585", "_blank")}
          />
          <p>Scannez le QR pour être redirigé vers le store et télécharger l'application</p>
          <div style={{ height: "200px" }}>
            <AwesomeQRCode
              options={{
                text: `${MY_DOMAIN}/redirect-to-store`,
                colorLight: "#ffffff",
                dotScale: 1,
                logoScale: 0.3,
                logoCornerRadius: 50,
                margin: 0,
              }}
            />
          </div>
        </div>
      </Winylo.Modal>
    </div>
  );
}
